const appConfig = {
    apiPrefix: '/',
    authenticatedEntryPath: '/home',
    firstLoginPath: '/home-login',
    selectProject: '/select-project',
    pollsDashboard: '/pollsDashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '',
    enableMock: false,
    AuthCheckTime: 1*process.env.REACT_APP_AUTH_CHECK_TIME,
    CacheTime: 1*process.env.REACT_APP_CACHE_TIME,
    APIURL: process.env.REACT_APP_API_URL,
    BASEURL: process.env.REACT_APP_BASE_URL,
    isCached: timeStamp => {
        return appConfig.CacheTime + timeStamp < Date.now()
    }
}

export default appConfig